<template>
  <GlPageWrap
    hide-title-on-mobile
    title="My Account"
  >
    <div class="content-wrap">
      <div class="bold-600 mb-4">
        Account info
      </div>
      <div class="flex space-between align-end mb-5 t-gap-4 t-flex-wrap m-column m-align-start ">
        <InfoBlock
          label="Login"
          :value="userData.email"
        />
        <div>
          <div class="tagging__key">
            No. of requests
          </div>
          <div class="tagging__value">
            <span v-if="!userData.requestsTotal">
              Unlimited
            </span>
            <div v-else>
              <span :class="[{ 'warning-text': isWithinPercentage(Math.max(userData.requestsLeft, 0), userData.requestsTotal) }]">
                {{ toComaSeparate(userData.requestsLeft) }}
              </span> /
              <span>{{ toComaSeparate(userData.requestsTotal) }}</span>
            </div>
          </div>
        </div>
        <div class="flex align-end">
          <InfoBlock
            class="mr-2"
            label="Active to"
            :value="userData.activeTo ? formatDate(userData.activeTo, 'dd.MM.yyyy') : null"
          />
          <gl-icon
            v-if="alarmActiveTo(userData.activeTo)"
            v-tooltip="'Your account will expire soon, please contact support to renew it'"
            class="mr-2 gl-icon--disabled"
            :height="24"
            name="def-report"
            :width="24"
          />
        </div>
        <button
          class="gl-button gl-button--dark gl-button--padder m-fullwidth"
          @click="resetPasswordModal = true"
        >
          Change password
        </button>
      </div>
      <div class="mb-4">
        <div class="tagging__key">
          Fiat Options
        </div>
        <vSelect
          class="scoring-formula-select mr-4 m-mr-0 m-fullwidth"
          :clearable="false"
          :options="fiatList"
          :value="fiat"
          @input="fiatChange"
        />
      </div>
      <template v-if="appConfig.VUE_APP_FORMULA_SELECT">
        <div class="bold-600 mb-4">
          Scoring Settings
        </div>
        <div
          class="mb-5"
        >
          <div class="tagging__key">
            Scoring formula
          </div>
          <div class="flex m-flex-wrap m-gap-3">
            <vSelect
              v-model="scoringFormula"
              class="scoring-formula-select mr-4 m-mr-0 m-fullwidth"
              :clearable="false"
              :options="scoringFormulaList"
              @input="formulaChange"
            />
          </div>
        </div>
      </template>
      <div class="bold-600 mb-4">
        API Keys
      </div>
      <div>
        <InfoBlock
          class="mb-4"
          label="API KEY"
          :value="userData.apikey"
          with-copy
        />
        <InfoBlock
          label="API Secret"
          :value="userData.apisecret"
          with-copy
        />
      </div>
    </div>
    <reset-password-modal
      v-if="resetPasswordModal"
      v-model="resetPasswordModal"
      :email="userData.email"
      @close="resetPasswordModal = false"
      @submit="resetPasswordModal = false"
    />
    <GlConfirmModal
      v-if="confirmChangeFiatModal"
      v-model="confirmChangeFiatModal"
      class="profile-confirm-modal"
      message="You are trying to switch main fiat currency and it may affect other tabs where this service involved."
      title="Are you sure?"
      @close="confirmChangeFiatModal = false"
      @confirm="resolveModalPromise(true)"
    />
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapState, mapActions } from "vuex";
// Components
import vSelect from 'vue-select'
import GlIcon from "@/components/gl-icon";
import InfoBlock from "@/components/gl-info-block";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import GlConfirmModal from "@/components/gl-confirm-modal";
import ResetPasswordModal from '../login/modals/resetPasswordModal'
// Utils
import { formatDate } from '@/utils/format-date'
import { alarmActiveTo, isWithinPercentage } from '@/utils/profile'
import { findFiatByKey } from "@/utils/format-by-price";
import { ScoringFormulaList } from '@/utils/globalConstants';
//mixins
import confirmModalMixin from '@/assets/mixins/confirmModalMixin'
import appConfig from '@/utils/appConfig'
import { toComaSeparate } from '@/utils/formatNumber'

export default {
  components: {
    GlIcon,
    vSelect,
    InfoBlock,
    GlPageWrap,
    ResetPasswordModal,
    GlConfirmModal
  },
  mixins: [confirmModalMixin],
  data() {
    return {
      resetPasswordModal: false,
      confirmChangeFiatModal: false,
      scoringFormulaList: ScoringFormulaList,
      scoringFormula: ScoringFormulaList[0],
      fiat: '',
    }
  },
  computed: {
    ...mapState('user', ['userData', 'fiatList']),
    appConfig() {
      return appConfig
    },
  },
  mounted() {
    appConfig.VUE_APP_FORMULA_SELECT
      ? this.scoringFormula = this.scoringFormulaList.find(f => f.key === this.userData.scoringFormula) || this.scoringFormulaList[0]
      : this.scoringFormulaList.find(f => f.key === appConfig.VUE_APP_SCORING_FORMULA)

    this.fiat = findFiatByKey(this.userData.preferredCurrency || this.fiatList[0].key)
  },
  methods: {
    toComaSeparate,
    isWithinPercentage,
    ...mapActions('user', ['setScoringFormula', 'getMe', 'setFiat']),
    formatDate,
    alarmActiveTo,
    formulaChange(scoringFormula) {
      this.setScoringFormula({ formula: scoringFormula.key }).then(({ success }) => {
        if (success) {
          this.scoringFormula = scoringFormula
          localStorage.setItem('scoringFormula', scoringFormula.key)
          this.getMe()
        }
      })
    },
    fiatChange(fiat) {
      this.confirmChangeFiatModal = true
      this.confirmModalPromice().then(e => {
        if (e) {
          this.setFiat({ preferredCurrency: fiat.key }).then(async ({ success }) => {
            if (success) this.fiat = fiat
            else this.fiat = findFiatByKey(this.userData.preferredCurrency)
          }).catch(async () => {
            this.fiat = findFiatByKey(this.userData.preferredCurrency)
          })
            .finally(async () => {
              await this.getMe()
              this.confirmChangeFiatModal = false
            })
        }
      })
    },
    restoreScoringSettings() {
      const defFormula = this.scoringFormulaList.find(f => f.key === appConfig.VUE_APP_SCORING_FORMULA)

      if (defFormula) this.formulaChange(defFormula)
    },
  },
}
</script>

<style>
.content-wrap {
  background: var(--white);
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(211, 211, 211, 0.64);
  padding: 24px;
}

.scoring-formula-select {
  width: 300px;
}

.profile-confirm-modal {
  z-index: 1001;
}

@media (max-width: 767px) {
  .scoring-formula-select {
    width: 180px;
  }
}
</style>
